<template>
  <div class="button-continue-page-scale">

    <button class="btn primary-bg body-small-bold show-on-size-l-smaller"
            v-on:click="goToDistancePage"
    >
      {{ $t('page-distance.info.start-test-button') }}
    </button>
  </div>
  <div class="page page-scale">
    <div class="left colored-centered-box">

      <div v-if="getDeviceType() == deviceTypes.MOBILE" class="intro" v-html="$t('page-scale.intro-mobile')"></div>
      <div v-else class="intro" v-html="$t('page-scale.intro')"></div>

      <button class="btn primary-bg body-small-bold margin-top-l hide-on-size-l-smaller"
              v-on:click="goToDistancePage" >
        {{ $t('page-distance.info.start-test-button') }}
      </button>
    </div>
    <div class="right colored-centered-box">
      <div class="container">
        <div v-bind:style="calculateCreditCardBoxSize" :class="['credit-card-box', getLanguage()]">
          <div class="credit-card-border"></div>
        </div>

        <div class="button-scale-container">
          <button class="round-circle small-circle primary-bg margin-right-m plus-minus-button"
                  @mousedown="increaseOrDecrease(false)"
                  @mouseleave="stopIncreaseOrDecrease"
                  @mouseup="stopIncreaseOrDecrease"
                  @touchstart="increaseOrDecrease(false)"
                  @touchend="stopIncreaseOrDecrease">−
          </button>
          <button class="round-circle small-circle primary-bg plus-minus-button"
                  @mousedown="increaseOrDecrease(true)"
                  @mouseleave="stopIncreaseOrDecrease"
                  @mouseup="stopIncreaseOrDecrease"
                  @touchstart="increaseOrDecrease(true)"
                  @touchend="stopIncreaseOrDecrease"
          >+
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import {getDeviceType} from "@/utils/utils";
import {deviceTypes} from "@/constants";

export default {
  data() {
    return {
      interval: false,
      incrementations: 0,
      linearIncrementations: 0,
    };
  },
  computed: {
    deviceTypes() {
      return deviceTypes
    },
    calculateCreditCardBoxSize() {
      return {
        height: this.$store.state.creditCardWidth + 'px',
        width: this.$store.state.creditCardHeight + 'px',
      };
    },
  },
  mounted() {
    this.$store.commit('setShowAboutButtonOnTopBar', false);
  },
  methods: {
    getDeviceType,
    increaseOrDecrease(increase, size = 1) {
      if (!this.interval) {

        this.interval = setInterval(() => {
          this.incrementations += 0.33;
          this.linearIncrementations = Math.max(0, Math.min(1, this.incrementations));
          console.log(this.linearIncrementations);
          this.$store.commit('increaseCreditCardBoxWidth', increase ? size * this.linearIncrementations : -size * this.linearIncrementations);
        }, 1000 / 45); // 45 fps
      }
    },
    stopIncreaseOrDecrease() {
      clearInterval(this.interval)
      this.interval = false
      this.incrementations = 0
      this.linearIncrementations = 0
    },
    goToDistancePage() {
      this.$store.commit('setShowAboutButtonOnTopBar', true);
      this.$changePageWithAnalyticsStep(this.$store, 'PageDistance');
    },
    getLanguage() {
      console.log(this.$i18n.locale)
      return this.$i18n.locale;
    },
  },
};
</script>
